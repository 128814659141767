jQuery( document ).ready(function($) {

  // Init in the backend
  var initImageSliderBlock = function( $block ) {
    var elem = $block.find('.post--slider')[0];
    if(elem) {
      new Swiper(elem, {
        loop: true,
        speed: 700,
        autoHeight: false,
        slidesPerView: 1,
        spaceBetween: 28,
        lazy: {
          checkInView: true,
          loadPrevNext: false,
          loadOnTransitionStart: false
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 3,
            loop: false,
          }
        },
        on: {
          init: function () {
            var sliderWraper = $(this.$el).parent();

            var imageHeight = sliderWraper.find('img').first().height();

          },
          slideChange: function() {
            var sliderWraper = $(this.$el).parent();

            var imageHeight = $(sliderWraper.find('img')[this.activeIndex]).height();

          }
        },
      });
    }
  }

  // Init on the frontend
  $('.js-image-slider-wrap').each(function(){
    initImageSliderBlock( $(this) );
  });

  // Initialize Before/After script in backend
  if( window.acf ) {
    window.acf.addAction( 'render_block_preview/type=image-slider', initImageSliderBlock );
  }
});
